<template>
  <v-container>
    <!-- toolbar -->
    <v-row>
      <v-col cols="12">
        <v-toolbar dense color="primary" dark flat rounded>
          <v-toolbar-title>Browse Travel Accommodations</v-toolbar-title>
          <v-spacer></v-spacer>

          <!-- back btn -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                depressed
                dark
                v-bind="attrs"
                v-on="on"
                @click="$router.go(-1)"
              >
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
            </template>
            <span>Back</span>
          </v-tooltip>
          <!-- back btn -->

          <!-- home/dashboard btn -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                depressed
                dark
                v-bind="attrs"
                v-on="on"
                @click="$router.push('/dashboard')"
              >
                <v-icon>mdi-home-outline</v-icon>
              </v-btn>
            </template>
            <span>Home</span>
          </v-tooltip>
          <!-- home/dashboard btn -->

          <!-- dark mode button -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                depressed
                dark
                v-bind="attrs"
                v-on="on"
                @click="$vuetify.theme.dark = !$vuetify.theme.dark"
              >
                <v-icon>{{
                  $vuetify.theme.dark
                    ? "mdi-lightbulb-outline"
                    : "mdi-lightbulb"
                }}</v-icon>
              </v-btn>
            </template>
            <span>Dark Mode</span>
          </v-tooltip>
          <!-- dark mode button -->

          <!-- sign out btn -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                depressed
                dark
                v-bind="attrs"
                v-on="on"
                @click="onSignOut"
                class="d-none d-sm-inline-flex"
              >
                <v-icon>mdi-logout-variant</v-icon>
              </v-btn>
            </template>
            <span>Sign Out</span>
          </v-tooltip>
          <!-- sign out btn -->

          <!-- notifications button -->
          <!-- <v-menu
            left
            bottom
            offset-y
            transition="slide-y-transition"
            v-if="userAccount.newDashboardMessageNotification"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                depressed
                color="primary"
                v-bind="attrs"
                v-on="on"
                class="d-none d-sm-inline-flex"
              >
                <v-badge color="pink">
                  <v-icon>mdi-bell</v-icon>
                </v-badge>
              </v-btn>
            </template>

            <v-list dense :class="$vuetify.theme.dark ? '' : 'grey lighten-3'">
              <v-list-item
                :class="$vuetify.theme.dark ? '' : 'grey lighten-3'"
                link
                @click="$router.push('/dashboard')"
                v-if="userAccount.newDashboardMessageNotification"
              >
                <v-list-item-title
                  >You have a new message. Click here to review your
                  timeline.</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu> -->
          <!-- notifications button -->
        </v-toolbar>
      </v-col>
    </v-row>
    <!-- toolbar -->
    <!-- items table -->
    <v-row>
      <v-col cols="12">
        <v-card outlined>
          <v-card-text class="text--primary">
            <v-row>
              <v-col
                cols="12"
                md="8"
                class="text-subtitle-1 font-weight-medium"
              >
                Accommodations for
                <span class="text-capitalize">{{ altDestination.name }}</span>
              </v-col>
              <v-col cols="12" md="4" align="end">
                {{ items.length }} Options Available
              </v-col>
            </v-row>
          </v-card-text>
          <v-data-table
            dense
            :items="sortedByCheckInDate"
            :items-per-page="-1"
            :headers="headers"
            fixed-header
            :height="650"
            @click:row="onViewAccommodation"
            sort-desc
          >
            <template v-slot:[`item.moreInfo`]="{ item }">
              <v-btn small text color="primary">Book/More Info</v-btn>
              {{ item.moreInfo }}
            </template>
            <template v-slot:[`item.checkInDate`]="{ item }">
              {{ formatDate(item.checkInDate) }}
            </template>
            <template v-slot:[`item.checkOutDate`]="{ item }">
              {{ formatDate(item.checkOutDate) }}
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <!-- items table -->
  </v-container>
</template>

<script>
import firebase from "@/plugins/firebase";
import Moment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(Moment);
import formatDate from "@/mixins/formatDate";
import onSignOut from "@/mixins/onSignOut";
const userAccounts = firebase.firestore().collection("userAccounts");
export default {
  mixins: [formatDate, onSignOut],
  async mounted() {
    if (!this.userID) return this.$router.push("/");
    await this.$store.dispatch("setLoading", true);

    // bind userAccount
    let userAccount = await this.$bind(
      "userAccount",
      userAccounts.doc(this.userID)
    );
    // bind userAccount

    // route guards
    if (
      !userAccount.canBook ||
      !userAccount.canAccess ||
      !userAccount.canBrowseInventory
    )
      return this.$router.push("/dashboard");
    // route guards

    // get eventReport
    let eventReport = await firebase
      .firestore()
      .collection("eventReports")
      .doc(userAccount.eventReportID)
      .get();
    eventReport = eventReport.data();
    this.eventReport = eventReport;
    // get eventReport

    // get specificWinner in eventReport winners array
    let specificWinner = eventReport.winners.filter(
      (i) => i.packageID == userAccount.packageID
    )[0];
    this.specificWinner = specificWinner;
    // get specificWinner in eventReport winners array

    // get specificWinner retail package to list inventory options
    let retailPackage = firebase
      .firestore()
      .collection("retailPackages")
      .doc(specificWinner.packageCode);
    retailPackage = await retailPackage.get();
    retailPackage = retailPackage.data();
    this.winnerRetailPackage = retailPackage;

    // get specificWinner retail package to list inventory options
    // let resortPropertyIds =  this[this.$route.params.id];
    let resortPropertyIds = await firebase
      .firestore()
      .collection("alternativeDestinations")
      .doc(this.$route.params.id)
      .get();
    resortPropertyIds = resortPropertyIds.data();
    resortPropertyIds.inventoryId = JSON.parse(resortPropertyIds.inventoryId);
    this.altDestination = resortPropertyIds;
    resortPropertyIds = resortPropertyIds.inventoryId;
    // resortPropertyIds = resortPropertyIds.filter(
    //   (el) => !retailPackage.inventoryProperties.some((el2) => el == el2)
    // );
    for (let index = 0; index < resortPropertyIds.length; index++) {
      const item = resortPropertyIds[index];
      this.constructInventoryItem(item);
    }

    // check is 3rd year surcharge is applicable to package
    let eventDate = moment(this.eventReport.eventDate, "YYYY-MM-DD");
    let cutoff = moment("2020-09-01", "YYYY-MM-DD");
    let isSameOrAfterCutoff = eventDate.isSameOrAfter(cutoff);

    // if package meets criteria for a potential 3rd year surcharge
    if (isSameOrAfterCutoff) {
      // check if package is in it's 3rd year
      let today = moment();
      // diff "true" param to include decimals (2.005, etc)
      let diff = today.diff(eventDate, "years", true);
      // if diff greater than 2 => package is in 3rd year => apply 3rd year surcharge
      if (diff > 2) {
        this.thirdYearSurcharge = true;
        this.updateHeaders();
      }
    }
  },
  computed: {
    sortedByCheckInDate() {
      if (!this.items) return;
      let items = this.items.sort((a, b) => {
        if (a.sortableCheckInDate === b.sortableCheckInDate) {
          return b.netProfit - a.netProfit;
        }
        return a.sortableCheckInDate - b.sortableCheckInDate;
      });
      return items;
    },
    userID() {
      return this.$store.state.userID;
    },
    loading() {
      return this.$store.state.loading;
    },
    // location() {
    //   if (!this.$route.params.id) return;
    //   switch (this.$route.params.id) {
    //     case "phoenix":
    //       return "Phoenix, Arizona";
    //     case "breckenridge":
    //       return "Breckenridge, Colorado";
    //     case "vailbeavercreek":
    //       return "Vail/Beaver Creek, Colorado";
    //     case "avonbeavercreek":
    //       return "Avon/Beaver Creek, Colorado";
    //     case "majesticcolorado":
    //       return "Majestic Colorado";
    //     case "orlando":
    //       return "Orlando, Florida";
    //     case "branson":
    //       return "Branson, Missouri";
    //     case "parkcity":
    //       return "Park City, Utah";
    //     case "lasvegas":
    //       return "Las Vegas, Nevada";
    //     case "williamsburg":
    //       return "Williamsburg, Virginia";
    //     case "cancun":
    //       return "Cancun, Mexico";
    //     case "loscabos":
    //       return "Los Cabos, Mexico";
    //     case "puertovallarta":
    //       return "Puerto Vallarta, Mexico";
    //     case "britishcolumbia":
    //       return "British Columbia, Canada";
    //     default:
    //       return "";
    //   }
    // },
  },
  data: () => ({
    altDestination: {},
    userAccount: "",
    eventReport: "",
    specificWinner: "",
    winnerRetailPackage: "",
    thirdYearSurcharge: false,
    items: [],
    // majesticcolorado: ["GP7", "GTR", "0005", "0867", "FAP", "TC3", "TCL"],
    // phoenix: ["5247", "8581", "D524", "D921", "DS01"],
    // breckenridge: ["GP7", "GTR"],
    // vailbeavercreek: ["0005", "0867"],
    // avonbeavercreek: ["FAP", "TC3", "TCL"],
    // orlando: [
    //   "0450",
    //   "0899",
    //   "5109",
    //   "5995",
    //   "8616",
    //   "A432",
    //   "C594",
    //   "C687",
    //   "RH55",
    //   "RYS",
    // ],
    // branson: ["1004", "3402", "4067", "4073", "3037"],
    // parkcity: ["0095", "0781", "0811", "2477", "2489"],
    // lasvegas: ["1837", "C610"],
    // williamsburg: ["3989", "5246"],
    // cancun: ["CCM", "LGU"],
    // loscabos: ["MCZ", "VDC", "VGR", "VDF", "TEC"],
    // puertovallarta: ["PRV"],
    // britishcolumbia: ["FMR", "SPE", "BIG", "FBC"],
    headers: [
      { text: "More Info/Book", value: "moreInfo" },
      { text: "Destination", value: "destination" },
      { text: "Resort Name", value: "resortName" },
      { text: "Check In Date", value: "checkInDate" },
      { text: "Check Out Date", value: "checkOutDate" },
      { text: "# Nights", value: "numberOfNights" },
      { text: "Bedrooms", value: "bedrooms" },
      { text: "Max Occupancy", value: "maxOccupancy" },
      { text: "Kitchen", value: "kitchen" },
    ],
  }),
  methods: {
    async onViewAccommodation(item) {
      item.packageCode = this.specificWinner.packageCode;
      await this.$store.dispatch("setSelectedInventoryListing", item);
      this.$router.push("/inventory/" + item.resortPropertyId);
    },
    async constructInventoryItem(item) {
      //
      // get inventory property
      let property = firebase
        .firestore()
        .collection("inventoryProperties")
        .doc(item);
      property = await property.get();
      property = property.data();

      // add costToOrg && bedrooms to property (filtering)
      property.costToOrg = this.winnerRetailPackage.costToOrg;
      property.retailBedrooms = this.winnerRetailPackage.bedrooms;

      // reduce to values needed for table
      delete property.archived;
      delete property.activities;
      delete property.airportInfo;
      delete property.amenities;
      delete property.checkInInfo;
      delete property.dateSubmitted;
      delete property.dateUpdated;
      delete property.destination;
      delete property.id;
      delete property["image-src"];
      delete property.otherInfo;
      delete property.overview;
      delete property.resortAddress;
      delete property.resortName;
      delete property.resortPropertyId;
      delete property.restaurants;
      delete property.restrictions;
      delete property.source;
      delete property.template;
      delete property.unitInfo;

      // get inventory items
      let listing = firebase
        .firestore()
        .collection("inventoryListings")
        .doc(item);
      listing = await listing.get();
      if (!listing.exists) return;
      let items = listing.data().items;

      // add property values to listing item
      items.forEach((i) => {
        i.commissionsPaidPercentage = property.commissionsPaidPercentage;
        i.costOfExtras = property.costOfExtras;
        i.costToOrg = property.costToOrg;
        i.highOccupancySurchargeAmount = property.highOccupancySurchargeAmount;
        i.minimumProfitAccepted = property.minimumProfitAccepted;
        i.peakSeasonSurcharge1Range = property.peakSeasonSurcharge1Range;
        i.peakSeasonSurcharge2Range = property.peakSeasonSurcharge2Range;
        i.peakSeasonSurchargeAmount = property.peakSeasonSurchargeAmount;
        i.retailBedrooms = property.retailBedrooms;
        i.taxesFeesPercentage = property.taxesFeesPercentage;
      });

      // removing any items less than 14 days from today
      let twoWeeksFromToday = moment().add(14, "d");
      items = items.filter((i) => {
        let isBefore = moment(i.checkInDate).isBefore(twoWeeksFromToday);
        if (!isBefore) return i;
      });

      // removing any items beyond users travelByDate
      let cutoff = moment(this.specificWinner.travelByDate, "YYYY-MM-DD");
      items = items.filter((i) => {
        let checkInDate = moment(i.checkInDate, "YYYY-MM-DD");
        let isSameOrBefore = checkInDate.isSameOrBefore(cutoff);
        if (isSameOrBefore) return i;
      });

      // if 3rd year surcharge (event date is after 9/1/2020 && diff > 2 years)
      if (this.thirdYearSurcharge) {
        items.forEach((i) => {
          i.thirdYearSurcharge = 599;
        });
        this.updateHeaders();
      }

      items = items.filter((i) => i.roomCost < 501);

      // format items
      items.forEach((i) => {
        // sortable checkInDate for table sorting
        i.sortableCheckInDate = i.checkInDate.replace(/-/g, "").trim();
        // create peakSeasonRanges for UI table display
        let peakSeasonRanges = [];
        i.peakSeasonSurcharge1Range.forEach((i) => peakSeasonRanges.push(i));
        i.peakSeasonSurcharge2Range.forEach((i) => peakSeasonRanges.push(i));
        i.peakSeasonRanges = peakSeasonRanges;
        // create moment peakSeason ranges
        let momentPeakSeason1Range = moment.range(
          i.peakSeasonSurcharge1Range[0],
          i.peakSeasonSurcharge1Range[1]
        );
        let momentPeakSeason2Range = moment.range(
          i.peakSeasonSurcharge2Range[0],
          i.peakSeasonSurcharge2Range[1]
        );
        // calc peakSeasonSurchargeAmount
        let originalPeakSeasonSurchargeAmount = i.peakSeasonSurchargeAmount;
        i.peakSeasonSurchargeAmount = 0;
        // if (
        //   momentPeakSeason1Range.contains(moment(i.checkInDate)) ||
        //   momentPeakSeason2Range.contains(moment(i.checkInDate))
        // ) {
        //   i.peakSeasonSurchargeAmount = originalPeakSeasonSurchargeAmount;
        // }
        // highOccupancy surcharge calculation
        // if (i.retailBedrooms >= i.bedrooms) i.highOccupancySurchargeAmount = 0;
        i.highOccupancySurchargeAmount = 0;

        // adding 3rd year surcharge if applicable
        if (this.thirdYearSurcharge) {
          i.totalSurcharges =
            i.peakSeasonSurchargeAmount + i.highOccupancySurchargeAmount + 599;
        } else {
          i.totalSurcharges =
            i.peakSeasonSurchargeAmount + i.highOccupancySurchargeAmount;
        }

        i.totalIncome = i.costToOrg + i.totalSurcharges;
        i.commissionsPaid = (i.commissionsPaidPercentage / 100) * i.costToOrg;
        i.commissionsPaid = parseInt(i.commissionsPaid);
        i.taxesAndFees = (i.roomCost / 100) * i.taxesFeesPercentage;
        i.taxesAndFees = parseInt(i.taxesAndFees);
        i.totalCost = i.commissionsPaid + i.roomCost + i.taxesAndFees;
        i.netProfit = i.totalIncome - i.totalCost;
        let wouldDisplay = false;
        if (i.netProfit >= i.minimumProfitAccepted) wouldDisplay = true;
        i.wouldDisplay = wouldDisplay;
        if (!i.archived) this.items.push(i);
      });

      await this.$store.dispatch("setLoading", false);
    },

    updateHeaders() {
      if (
        !this.headers.some((header) => header.value === "thirdYearSurcharge")
      ) {
        this.headers.push({
          text: "Third Year Surcharge",
          value: "thirdYearSurcharge",
        });
      }
    },
  },
};
</script>

<style>
tr {
  cursor: pointer;
  user-select: none !important;
}
td {
  height: 36px !important;
  width: 1px;
  white-space: nowrap;
  text-transform: capitalize;
}
</style>
